import * as React from "react"
import Layout from "../components/layout";
import * as Container from "../components/container/container";

const IndexPage = (props) => {
    return (
        <Layout pageTitle="OdD" props={props}>
            <main id="main">
                <Container.HeaderPart background="white no-image">
                    <div className="headline">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h1>Christoph Schappeler – ein einflussreicher Theologe</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container.HeaderPart>
                <Container.Part background="white">
                    <Container.Row>
                        <div className="col-6">
                            <figure className="center">
                                <img src={"/images/Memmingen/schappeler.jpg"} alt=""/>
                            </figure>
                        </div>
                        <div className="col-6">
                            <p>
                                Der 1472 in St. Gallen geborene Christoph Schappeler trat als studierter Rechtswissenschaftler
                                und Theologe 1513 eine Predigerstelle in der Memminger St.-Martins-Kirche an. Er orientierte sich
                                an der Lehre von Ulrich Zwingli, der theologische Grundpositionen Luthers teilte, aber die Rolle
                                der Obrigkeit kritischer sah und die Idee einer gemeindeorientierten Gesellschaft vertrat.
                                Memmingen wechselte als eine der ersten Reichsstädte Süddeutschlands ins Lager der Reformation.
                            </p>
                            <p>
                                Die aufständischen Bauern unterstützte Schappeler vor allem bei ihrer Forderung nach der Abschaffung
                                der Leibeigenschaft. Gemeinsam mit Lotzer war er an der Abfassung der Memminger Bundesordnung
                                und der Zwölf Artikel beteiligt. Er schloss sich aber nicht dem Aufstand an, sondern versuchte,
                                die Bauern von einem gewaltfreien Weg zu überzeugen. Als im Juni 1525, zum Ende des Bauernkriegs,
                                der Schwäbische Bund in Memmingen einrückte, musste er nach St. Gallen fliehen. Bis zu seinem
                                Tod 1551 war er an verschiedenen Kirchen als Prediger tätig.
                            </p>
                            <p/>
                        </div>
                    </Container.Row>
                </Container.Part>
            </main>
        </Layout>
    )
}

export default IndexPage
